import { IUser } from 'app/shared/model/user.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';

export interface IUserInfo {
  id?: number;
  age?: number | null;
  gender?: keyof typeof Gender | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipcode?: string | null;
  placeId?: string | null;
  isValid?: boolean | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserInfo> = {
  isValid: false,
};
