import { IPoliticianIssues } from 'app/shared/model/politician-issues.model';

export interface IIssues {
  id?: number;
  title?: string | null;
  description?: string | null;
  category?: string | null;
  politicianIssues?: IPoliticianIssues[] | null;
}

export const defaultValue: Readonly<IIssues> = {};
