import dayjs from 'dayjs';
import { IQuestion } from 'app/shared/model/question.model';
import { IPollDivision } from 'app/shared/model/poll-division.model';
import { PollStatus } from 'app/shared/model/enumerations/poll-status.model';

export interface IPoll {
  id?: number;
  title?: string | null;
  description?: string | null;
  tip?: string | null;
  successMessage?: string | null;
  incompleteMessage?: string | null;
  status?: keyof typeof PollStatus | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  totalQuestions?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  questions?: IQuestion[] | null;
  pollDivisions?: IPollDivision[] | null;
}

export const defaultValue: Readonly<IPoll> = {};
